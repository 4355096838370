import React from 'react';

import ContactForm from '../../components/form/ContactForm';

const ContactsUs = () => {
    return (
        <section id="contacts" className="block bg-gray-light spacer p-top-xl p-bottom-xl">
            <div className="wrapper">
                <div className="title text-max-width-2">
                    <h2>Want to schedule an appointment? Drop us a line and we'll get back to you shortly!</h2>
                </div>

                <div className="description text-max-width-2">
                    <p>Fill out the form below or call us at <a href="tel:+5125022444">(512) 502-2444</a>.</p>
                </div>

                <div className="contact-form-shortcode spacer p-top-lg">
                    <ContactForm />
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
