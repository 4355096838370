import React from 'react';
import { Link } from 'react-scroll';
import michael from "../../assets/img/michael.png";

const HomeAboutText = () => {
    return (
        <div id="about-text" className="wrapper">
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h2>Choose The Best Care For Yourself and Your Family. Choose Far West Dental.</h2>
                    </div>

                    <div className="signature spacer m-top-lg">
                        <div className="signature-img">
                            <img src={michael} alt="Michael Barbe" />
                        </div>

                        <div className="signature-content">
                            <h5 className="signature-name">Michael Barbe</h5>
                            <p className="signature-position">D.D.S</p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>Welcome to Far West Dental! We appreciate the confidence you have placed in us, and we are pleased that you have selected our office for the wellness of your oral health. We love seeing new patients, and we welcome the opportunity to work with you to create a healthy and beautiful smile.</p>
                    </div>

                    <div className="description-2 spacer p-top-lg">
                        <p>Our goal is to offer superior dental care to our patients using the latest technology.  We provide a comfortable setting to care for the oral health of our patients.  We strive to treat our patients as family and friends. </p>
                    </div>

                    <div className="button spacer p-top-lg">
                        <Link to="services" href="#services" className="btn btn-primary scroll-to-id">See Our Services</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutText;
