import React from 'react';
import logo from "../../assets/img/logo_transparent.png"

const FooterLogo = () => {
    return (
        <div className="logo logo-primary">
            <img src={logo} alt="Logo" />
        </div>
    );
};

export default FooterLogo;
