import React from 'react';
import logo from "../../assets/img/logo_transparent.png";

const DarkLogo = () => {
    return (    
        <a className="logo logo-primary adv-dark transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL +"/" }>
            <img src={logo} alt="Logo" />
        </a>
    );
};

const LightLogo = () => {
    return (    
        <a className="logo logo-secondary adv-light transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL +"/" }>
            <img src={logo} alt="Logo" />
        </a>
    );
};

const ShowLogo = ( color ) => {
    const showColor = color.showColor;

    if ( showColor === "dark" ) {
      return <DarkLogo />;
    }

    return <LightLogo />;
};

const HomeHeaderLogo = ( props ) => {
    return (
        <div className="header-logo">
            <ShowLogo showColor={ props.logoColor } />
        </div>
    );
};

export default HomeHeaderLogo;
