import React, { Component } from 'react';
import axios from 'axios';

class ContactForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            values: {
                author: '',
                phone: '',
                email: '',
                question: ''
            },
            successMessage: "Form submitted successfully! We'll be in touch soon. (placeholder, form not actually sending anywhere)",
            warningMessage: 'Please fill out the entire form.',
            errorMessage: 'Something went wrong. Try again or call us.',
            showAlert: false,
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000
        };
    }

    submitForm = async e => {
        e.preventDefault();

        if ( document.querySelector( '#alert' ) ) {
            document.querySelector( '#alert' ).remove();
        }

        this.setState( { isSubmitting: true } );

        // axios.post( 'https://store.adveits.com/API/form.php', this.state.values, {
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json; charset=UTF-8'
        //     },
        // }, ).then( response => {
        //     if ( response.data.status === 'success' ) {
        //         this.setState( { responseMessage: this.state.successMessage } );
        //     }

        //     if ( response.data.status === 'warning' ) {
        //         this.setState( { responseMessage: this.state.warningMessage } );
        //     }

        //     if ( response.data.status === 'error' ) {
        //         this.setState( { responseMessage: this.state.errorMessage } );
        //     }

        //     this.callAlert( this.state.responseMessage, response.data.status )
        // } ).catch( error => {
        //     this.callAlert( this.state.errorMessage, 'error' )
        // } );
        this.setState( { responseMessage: this.state.successMessage } );
        this.callAlert( this.state.responseMessage, "success" );
        this.setState({
            values: {
                author: '',
                phone: '',
                email: '',
                question: ''
            },
        })
    };

    removeAlert = () => {
        clearTimeout( this.state.alertTimeout );
        this.setState( {
            alertTimeout: setTimeout( function() {
                    var element = document.querySelector( '#alert' );
                    element.classList.remove( 'fadeIn' );
                    element.classList.add( 'fadeOut' );
                    setTimeout( function() {
                        element.remove()
                    }, 900 )
                }, this.state.delay
            )
        } );
    };

    callAlert = ( message, type ) => {
        if ( ! document.querySelector( '#alert' ) ) {
            if ( type === 'success' ) {
                this.setState( { alertClass: 'success' } )
            }

            if ( type === 'error' ) {
                this.setState( { alertClass: 'danger' } )
            }

            if ( type === 'warning' ) {
                this.setState( { alertClass: 'warning' } )
            }

            this.setState({ showAlert: true });

            this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState( {
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        } );

    render() {
        return (
            <form onSubmit={ this.submitForm } method="post" action="form.php" id="cf-1" className="contact-form">
                <div className="form-group form-group-xs">
                    <p className="input-group gutter-width-md no-space">
                        <span className="gutter-width">
                            <input name="author" 
                                type="text" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-name" 
                                placeholder="Name (First, Last)*"
                                value={ this.state.values.author }
                                onChange={ this.handleInputChange } 
                                required="required" />
                        </span>

                        <span className="gutter-width">
                            <input name="phone" 
                                type="text" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-phone" 
                                placeholder="Phone *"
                                value={ this.state.values.phone }
                                onChange={ this.handleInputChange } 
                                required="required" />
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-xs">
                    <p className="input-group gutter-width-md no-space">
                        <span className="gutter-width">
                            <input name="email" 
                                type="email" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-email" 
                                placeholder="Email" 
                                value={ this.state.values.email }
                                onChange={ this.handleInputChange }
                                required="required" />
                        </span>

                        <span className="gutter-width">
                            <input name="question" 
                                type="text" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-question" 
                                placeholder="Message *"
                                value={ this.state.values.question }
                                onChange={ this.handleInputChange } 
                                required="required" />
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-xs mb-0">
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>

                {this.state.showAlert && (
                    <div id="alert" className={`animated fadeIn alert alert--shadow alert-${this.state.alertClass}`}>
                        {this.state.responseMessage}
                    </div>
                )}
            </form>
        );
    }
}

export default ContactForm;
