import React, { useState } from 'react';
import Fade from '@material-ui/core/Fade';
import michael from "../../assets/img/michael_500x550.png";
import vanya from "../../assets/img/vanya.jpeg";
import teresa from "../../assets/img/teresa.png";

const OurTeam = () => {
    const [open_1, setOpen1] = useState( false );
    const [open_2, setOpen2] = useState( false );
    const [open_3, setOpen3] = useState( false );

    const handleClick = ( id ) => {
        if ( id === 1 ) { 
            setOpen1( (prev) => !prev );
        }

        if ( id === 2 ) { 
            setOpen2( (prev) => !prev );
        }

        if ( id === 3 ) { 
            setOpen3( (prev) => !prev );
        }
        
    };

    return (
        <section id="our-team" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title text-max-width">
                    <h2>Meet the Team</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="team-member">
                            <div className="team-member-top">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src={michael} alt="Michael Barbe" />
                                    </div>
                                </div>

                                <div className="team-member-social">
                                    <button onClick={ ( e ) => handleClick( 1 ) } type="button" className="btn btn-primary btn-square min-w-auto"><i className="fas fa-plus i-default"></i></button>

                                    <Fade in={ open_1 } timeout={ 600 }>
                                        <div className="team-member-social-btn-items" style={ { display: "block" } }>
                                            <div className="team-member-social-btn-item">
                                                <a title="Facebook" className="btn btn-link btn-square min-w-auto" href="https://www.facebook.com/adveits/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f i-default"></i></a>
                                            </div>

                                            <div className="team-member-social-btn-item">
                                                <a title="LinkedIn" className="btn btn-link btn-square min-w-auto" href="https://www.linkedin.com/company/18135051/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in i-default"></i></a>
                                            </div>

                                            <div className="team-member-social-btn-item">
                                                <a title="Instagram" className="btn btn-link btn-square min-w-auto" href="https://www.instagram.com/adveits/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram i-default"></i></a>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>

                            <div className="team-member-content">
                                <div className="team-member-position">
                                    <p>Doctor</p>
                                </div>

                                <h4 className="team-member-t-head">Michael Barbe</h4>

                                <div className="team-member-description">
                                    <p>Dr. Barbe graduated from the University of Texas at Austin with a degree in Biology and completed his dental degree at Baylor College of Dentistry in 1997.  He worked as an associate dentist for four years in Dallas before moving to Austin to establish his own practice in Northwest Hills in 2001. He is committed to providing exceptional quality dental care and enjoys getting to know each patient on a personal level. In his free time, Dr. Barbe loves to travel, bicycle around Town Lake, watch UT football, and relax on Lake LBJ with his family.</p>
                                    <em>"The best part of my job is giving someone a beautiful smile." </em>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="team-member">
                            <div className="team-member-top">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src={vanya} alt="Vanya Wolf" />
                                    </div>
                                </div>

                                <div className="team-member-social">
                                    <button onClick={ ( e ) => handleClick( 2 ) }type="button" className="btn btn-primary btn-square min-w-auto"><i className="fas fa-plus i-default"></i></button>

                                    <Fade in={ open_2 } timeout={ 600 }>
                                        <div className="team-member-social-btn-items" style={ { display: "block" } }>
                                            <div className="team-member-social-btn-item">
                                                <a title="Facebook" className="btn btn-link btn-square min-w-auto" href="https://www.facebook.com/adveits/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f i-default"></i></a>
                                            </div>

                                            <div className="team-member-social-btn-item">
                                                <a title="LinkedIn" className="btn btn-link btn-square min-w-auto" href="https://www.linkedin.com/company/18135051/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in i-default"></i></a>
                                            </div>

                                            <div className="team-member-social-btn-item">
                                                <a title="Instagram" className="btn btn-link btn-square min-w-auto" href="https://www.instagram.com/adveits/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram i-default"></i></a>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>

                            <div className="team-member-content">
                                <div className="team-member-position">
                                    <p>Dental Assistant</p>
                                </div>

                                <h4 className="team-member-t-head">Vanya Wolf</h4>

                                <div className="team-member-description">
                                    <p>Vanya, the office manager and dental assistant, joined Dr Barbe's team in 2012.An Austin native as well, Vanya studied Psychology and Child Development at the University of Texas. She enjoys studying natural health and staying active and fit!</p>
                                    <em>"My favorite part of my job is being able to help people and the  one on one interaction with our amazing patients!"</em>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="team-member">
                            <div className="team-member-top">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src={teresa} alt="Teresa" />
                                    </div>
                                </div>

                                <div className="team-member-social">
                                    <button onClick={ ( e ) => handleClick( 3 ) } type="button" className="btn btn-primary btn-square min-w-auto"><i className="fas fa-plus i-default"></i></button>

                                    <Fade in={ open_3 } timeout={ 600 }>
                                        <div className="team-member-social-btn-items" style={ { display: "block" } }>
                                            <div className="team-member-social-btn-item">
                                                <a title="Facebook" className="btn btn-link btn-square min-w-auto" href="https://www.facebook.com/adveits/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f i-default"></i></a>
                                            </div>

                                            <div className="team-member-social-btn-item">
                                                <a title="LinkedIn" className="btn btn-link btn-square min-w-auto" href="https://www.linkedin.com/company/18135051/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in i-default"></i></a>
                                            </div>

                                            <div className="team-member-social-btn-item">
                                                <a title="Instagram" className="btn btn-link btn-square min-w-auto" href="https://www.instagram.com/adveits/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram i-default"></i></a>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>

                            <div className="team-member-content">
                                <div className="team-member-position">
                                    <p>Dental Hygienist</p>
                                </div>

                                <h4 className="team-member-t-head">Teresa</h4>

                                <div className="team-member-description">
                                    <p>Teresa, our hygienist, truly loves teaching patients how to achieve their best oral health. She spends her free time playing with her dog, doing yoga and being outdoors.</p>
                                    <em>"I love teaching patients how to ahieve their best oral health!"</em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurTeam;
