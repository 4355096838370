import React from 'react';

function HomeAboutFeatures() {
    return (
        <div id="features" className="wrapper spacer p-top-xl">
            <div className="row gutter-width-md with-pb-lg">
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="feature-item">
                        <h2>Our Values</h2>
                    </div>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="feature-item">
                        <h4>Quality</h4>
                        <p>We are committed to providing the highest quality dental care, using the most effective and latest technologies.</p>
                    </div>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="feature-item">
                        <h4>Comfort</h4>
                        <p>Our goal is to ensure that every patient feels at ease and leaves our office with a happy, healthy smile.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutFeatures;
