import React from 'react';

const  NewsImg = () => {
    return (
        <div className="post-img">
            <div className="img object-fit">
                <div className="object-fit-cover">
                    <img src="assets/img/placeholder/1050x600.jpg" alt="Do You Need to Prepare before Going to the Dentist?" />
                </div>
            </div>

            <div className="post-date">
                <div className="post-date-0">
                    <p><span className="post-date-number">28</span>April</p>
                </div>
            </div>
        </div>
    );
};

export default NewsImg;
