import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';

import AccordionItems from '../../data/services/accordionOneItems.json';

const Accordion1 = () => {
    const [open, setOpen] =useState(0);

    const handleClick = ( e ) => {
        let target = e.target.getAttribute( "data-target" );

        if ( open === target) {
            setOpen( "#collapse-0" );
        } else {
            setOpen( target);
        }
    };

    return (
        <div id="accordion-services-1" className="accordion accordion-services">
            { AccordionItems && AccordionItems.map( ( item, key ) =>{
                return(
                    <div key={ key } className={ "card " + ( open === ( "#collapse-" + item.id ) ? "active" : " " ) }>
                        <div className="card-header" id={ "heading-" + item.id }>
                            <button onClick={ (e) => handleClick(e) } className={ "btn btn-lg btn-link stretched-link " + ( open === ( "#collapse-" + item.id ) ? " " : "collapsed" ) }  type="button" data-toggle="collapse" data-target={ "#collapse-" + item.id } aria-expanded="false" aria-controls={ "collapse-" + item.id }>
                                <span className="h4 after">{ item.title }</span>
                                <i className="fas fa-angle-right i-large"></i>
                            </button>
                        </div>
        
                        <Collapse in={ open ===  ( "#collapse-" + item.id )}>
                            <div id={ "collapse-" + item.id } className="collapse show" aria-labelledby={ "heading-" + item.id } data-parent={ "#accordion-services-" + item.id }>
                                <div className="card-body">
                                    <p>{ item.description } </p>
            
                                    {/* <p className="card-price">{ item.price }</p> */}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion1;
