import React from 'react';
import pdf from "../../assets/New_Patient_Packet.pdf";

const NewPatients = () => {
    return (
        <section id="new-patients" className="block spacer p-top-xl p-bottom-xl">
            <div className="wrapper">
                <div className="title text-center">
                    <h2>New Patients</h2>
                    <p>New Patient Packet (pdf)</p>
                    <a href={pdf} download="New Patient Packet" className="btn btn-primary">Download</a>
                </div>
            </div>
        </section>
    );
};

export default NewPatients;
