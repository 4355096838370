import React from 'react';
import { Link } from 'react-scroll';

const HomeInfo = () => {
    return (
        <section id="information" className="block">
            <div className="wrapper">
                <div className="row no-gutters information-items">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-1">
                            <div className="information-icon">
                                <i className="hosco-icon-call-2"></i>
                            </div>

                            <h4 className="information-t-head">Get In Touch</h4>

                            <div className="information-description">
                                <p>Call us or fill out the form below to set up an appointment.</p>
                            </div>

                            <div className="information-btn">
                                <a title="Call us now" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase" href="tel:+5125022444">Call us now</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-2 information-icon-margin">
                            <div className="information-icon">
                                <i className="hosco-icon-dental-care"></i>
                            </div>

                            <h4 className="information-t-head">Where Are We?</h4>

                            <div className="information-description">
                                <p>3624 N Hills Dr Ste B200, Austin, TX 78731 - right across the street from the HEB.</p>
                            </div>

                            <div className="information-btn">
                                <Link to="google-maps" title="See on map" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase scroll-to-id" href="#google-maps">See on map</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-3">
                            <div className="information-icon">
                                <i className="hosco-icon-calendar"></i>
                            </div>

                            <h4 className="information-t-head">Hours</h4>

                            <div className="information-description">
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Monday - Thursday:</span><span className="text-right">8:00am - 5:00pm</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Saturday:</span><span className="text-right">Closed</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Sunday:</span><span className="text-right">Closed</span></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeInfo;
