import React from 'react';
import { Link } from 'react-scroll';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block with-img with-information-items" style={ { backgroundImage: `url('assets/img/hero.jpeg')` } }>
            <div className="wrapper d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                        <h1 className="large">Let us brighten <br />your smile!</h1>
                    </div>

                    <div className="description spacer p-top-lg text-max-width">
                        <p>We offer superior dental care in Northwest Hills, Austin. See us for all of your oral health needs.</p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <Link to="about-us" href="#about-us" className="btn btn-primary scroll-to-id">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
