import React from 'react';
import logo from "../../assets/img/logo_transparent.png";

const HeaderLogo = () => {
    return (
        <div className="header-logo">
            <a className="logo logo-primary transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                <img src={logo} alt="Logo" />
            </a>
        </div>
    );
};

export default HeaderLogo;
